import '../scss/foundation.scss';
import '../scss/main.scss';

// Either import everything
//import 'foundation-sites;

// Or import individual modules
/**/
import 'foundation-sites/dist/js/plugins/foundation.core';
import 'foundation-sites/dist/js/plugins/foundation.dropdownMenu.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.keyboard.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.box.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.nest.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.mediaQuery';
import 'foundation-sites/dist/js/plugins/foundation.util.imageLoader';
import 'foundation-sites/dist/js/plugins/foundation.util.motion';
import 'foundation-sites/dist/js/plugins/foundation.util.triggers';
import 'foundation-sites/dist/js/plugins/foundation.util.touch';
import 'foundation-sites/dist/js/plugins/foundation.equalizer';

function countdown(elementName, minutes, seconds) {
	var element, endTime, hours, mins, msLeft, time;

	function twoDigits(n) {
		return (n <= 9 ? "0" + n : n);
	}

	function updateTimer() {
		msLeft = endTime - (+new Date);
		if (msLeft < 1000) {
			element.innerHTML = "00:00";
			document.getElementById('timer-popup').className = 'active';
			document.getElementById('timer-popup-bg').className = 'active';
		} else {
			time = new Date(msLeft);
			hours = time.getUTCHours();
			mins = time.getUTCMinutes();
			element.innerHTML = (hours ? hours + ':' + twoDigits(mins) : twoDigits(mins)) + ':' + twoDigits(time.getUTCSeconds());
			setTimeout(updateTimer, time.getUTCMilliseconds() + 500);
		}
	}

	element = document.getElementById(elementName);
	endTime = (+new Date) + 1000 * (60 * minutes + seconds) + 500;
	updateTimer();
}

function clearForm() {
	$('.form').val('');
	$('.form--alt option:eq(0)').prop('selected', true);
}

document.addEventListener("DOMContentLoaded", function () {
	$(document).foundation();

	countdown( "timer-countdown", 10, 0 );

	// close security message on maincontent click
	$('#maincontent').on('click', function (e) {
		$('.security-message').removeClass('active');	
	});

	// close timer popup
	$('.timer-button').on('click', function (e) {
		//$('#timer-popup').removeClass('active');
		//$('#timer-popup-bg').removeClass('active');
		e.preventDefault();
	});

	// security message toggle
	$('.unlocked').on('click', function(e) {
		$('.security-message').toggleClass("active"); 
		e.preventDefault();
	});

	// submit form
	$('.submit').on('click', function(e) {
		//alert('Complete');
		$('#complete-popup').addClass("active");
		$('#complete-popup-bg').addClass("active");
		e.preventDefault();
		clearForm();
	});

	// privacy popup
	$('.privacy-link').on('click', function(e) {
		$('#privacy-popup').addClass("active");
		$('#privacy-popup-bg').addClass("active");
		e.preventDefault();
	});	

	// close privacy popup
	$('.timer-button').on('click', function (e) {
		$('#privacy-popup').removeClass('active');
		$('#privacy-popup-bg').removeClass('active');
		e.preventDefault();
	});	

	// $(window).bind('beforeunload', function(e){
	// 	return "If you refresh it will jeopardise the application? Are you sure you want to refresh?";
	// });

});


